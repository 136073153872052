<template>
	<UCard
		:ui="{
			base: 'px-2 cursor-pointer',
			background: 'bg-transparent dark:bg-transparent',
			ring: 'ring-0 transition ease-in-out hover:ring-1 duration-300',
			divide: 'divide-y-0',
			rounded: 'rounded-none transition ease-in-out hover:rounded duration-300',
			shadow: 'shadow-none transition ease-in-out hover:shadow duration-300',
			header: {
				base: 'relative',
				padding: 'px-0 sm:px-0 sm:py-3',
			},
			body: {
				base: 'sm:min-h-[110px] lg:min-h-[80px] xl:min-h-[60px]',
				padding: 'py-0 px-0 sm:p-0',
			},
			footer: {
				base: 'mt-1',
				padding: 'px-0 sm:px-0 sm:py-3',
			},
		}"
		@click="toEventDetails()"
	>
		<template #header>
			<NuxtImg :src="props.event.thumbnail.path.fs" class="w-full aspect-[5/3] cover-image rounded-[4px]" />
			<!-- <UBadge
                class="absolute top-8 left-2 sm:top-7 sm:left-4 text-[0.6rem] sm:text-[0.7rem] md:text-xs"
                color="white"
                variant="solid"
                :ui="{
                    color: {
                        white: {
                            solid: 'ring-1 ring-inset p-2 ring-wemotooPrimary text-wemotooPrimary font-jakarta font-semibold',
                        },
                    },
                }"
                >{{ props.event.total_joined }} joining
            </UBadge> -->
		</template>

		<h3 class="text-lg font-bold font-jakarta text-wemotooBlue mb-2 title">{{ props.event.name }}</h3>
		<p class="text-[14px] font-jakarta text-wemotooGrey text-gray">
			<!-- {{ props.event.event_date.replace("to", "-") }} -->
			{{ eventDate }}
		</p>
		<template #footer>
			<div class="flex items-end">
				<ButtonImage title="Purchase Ticket" title-class="text-2xl h-8" @click="toEventDetails()" />
			</div>
		</template>
	</UCard>
</template>

<script lang="ts" setup>
import type { WemotooEvent } from '../../types';

const props = defineProps<{
	event: WemotooEvent;
}>();

const toEventDetails = async () => {
	const firebase = useFirebaseStore();

	firebase.logCustomEvent(SELECT_ITEM, {
		event_id: props.event.id,
		event_name: props.event.name,
	});

	navigateTo(`/events/${props.event.id}`);
};

const eventDate = computed(() => {
	try {
		const parts = props.event.event_date.split('to');

		const startDate = parts[0].trim();
		const endStr = parts[1].trim();
		const time = endStr.substring(11).trim();
		const endDate = endStr.substring(0, 10).trim();

		return `${startDate} - ${endDate} · ${time}`;
	} catch {
		return props.event.event_date.replace('to', '-');
	}
});
</script>

<style scoped>
.title {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	/* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
}
</style>
